/*!
   =========================================================
 * Vue Paper Dashboard - v2.0.0
   =========================================================
 * Product Page: http://www.creative-tim.com/product/paper-dashboard
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard/blob/master/LICENSE.md)
   =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import Vue from 'vue'
import App from './App'
import router from '@/router/index'
import store from '@/store'

import PaperDashboard from './plugins/paperDashboard'
import VueClipboard from 'vue-clipboard2'

import 'vue-notifyjs/themes/default.css'
import '@/assets/css/themify-icons.css'

Vue.use(VueClipboard)
Vue.use(PaperDashboard)

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
